import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./Admin/Components/Redux/Store";
import { BrowserRouter } from "react-router-dom";
import { DataProvider } from "./Admin/Components/Context/DataContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <DataProvider>
          <App />
        </DataProvider>
      </Provider>
    </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
