import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import { thunk } from "redux-thunk";
import rootReducer from "./Root-Reducer";
import { ENVIRONMENT } from "../Config/config";

const middlewares = [thunk];

if (ENVIRONMENT === "local") {
  middlewares.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
