const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const API_URL_PROD = process.env.REACT_APP_BASE_URL_PROD;
const API_URL_LOCAL = process.env.REACT_APP_BASE_URL_LOCAL;


const IMAGE_BASE_URL_PROD = process.env.REACT_APP_IMAGE_BASE_URL_PROD;
const IMAGE_BASE_URL_LOCAL = process.env.REACT_APP_IMAGE_BASE_URL_LOCAL;

const ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
const REGION = process.env.REACT_APP_REGION;
const BUCKET_NAME = process.env.REACT_APP_BUCKET;
const config = {
  base: {
    url: API_URL_LOCAL,
    imageUrl: IMAGE_BASE_URL_LOCAL,
  },
  s3: {
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
    region: REGION,
    bucket: BUCKET_NAME,
  },
};
console.log(IMAGE_BASE_URL_LOCAL);

//CHECK FOR ENVIRONMENT
if (ENVIRONMENT === "prod") {
  config.base.url = API_URL_PROD;
  config.base.imageUrl = IMAGE_BASE_URL_PROD;
} else {
  config.base.url = API_URL_LOCAL;
  config.base.imageUrl = IMAGE_BASE_URL_LOCAL;
}

export { config, ENVIRONMENT };


