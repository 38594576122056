import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { getToken, isUserLoggedOut } from "../Utils/util";

function MyAccount() {
  const userToken = getToken();
  const navigate = useNavigate();
  const handleLogout = () => {
    isUserLoggedOut();
    navigate("/login");
    alert("Logged out");
  };

  return (
    <div>
      <div className="rounded-lg bg-white p-8 shadow-2xl font-Poppins">
        {userToken?.Token ? (
          <div>
            <h2 className="text-primary font-semibold text-xl">Your Account</h2>

            <p className="mt-2 text-thirdColor text-sm">{userToken?.email}</p>

            <div className="mt-4 flex gap-2">
              <Link
                to="/myaccount/changePassword"
                className="rounded bg-primary px-4 py-2 text-sm font-medium text-secondary uppercase"
              >
                My Account
              </Link>

              <button
                onClick={handleLogout}
                className="rounded border border-primary px-4 py-2 text-sm font-medium text-gray-600 uppercase"
              >
                Logout
              </button>
            </div>
          </div>
        ) : (
          <div>
            <p className="mt-2 text-thirdColor text-sm">Access account</p>

            <div className="mt-4 flex gap-2">
              <Link
                to="/login"
                className="rounded border border-primary px-4 py-2 text-sm font-medium text-gray-600"
              >
                Log In
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MyAccount;
