import { MdOutlineCategory, MdOutlineDashboard } from "react-icons/md";
import { BiSliderAlt } from "react-icons/bi";
import { HiOutlineCog } from "react-icons/hi";
import { FaFileInvoiceDollar, FaRegCircleUser } from "react-icons/fa6";
import { SiUnitednations, SiBookstack } from "react-icons/si";
export const headerData = [
  {
    id: 1,
    pathname: "/",
    pageName: "Dashboard",
    img: <MdOutlineDashboard />,
    roles: ["admin", "subadmin"],
  },
  {
    id: 2,
    pathname: "/countries",
    pageName: "Countries",
    img: <SiUnitednations />,
    roles: ["admin", "subadmin"],
  },
  {
    id: 3,
    pathname: "/labels",
    pageName: "Labels",
    img: <BiSliderAlt />,
    roles: ["admin", "subadmin"],
  },
  {
    id: 4,
    pathname: "/user-list",
    pageName: "Users",
    img: <FaRegCircleUser />,
    roles: ["admin", "subadmin"],
  },
  {
    id: 5,
    pathname: "/short-category",
    pageName: "Short Category",
    img: <MdOutlineCategory />,
    roles: ["admin", "subadmin"],
  },
  {
    id: 6,
    pathname: "/short-series",
    pageName: "Shorts Series",
    img: <MdOutlineCategory />,
    roles: ["admin", "subadmin"],
  },
  {
    id: 7,
    pathname: "/novel-category",
    pageName: "Novel Category",
    img: <MdOutlineCategory />,
    roles: ["admin", "subadmin"],
  },
  {
    id: 8,
    pathname: "/novel-list",
    pageName: "Novel",
    img: <SiBookstack />
	,
    roles: ["admin", "subadmin"],
  },
  {
    id: 9,
    pathname: "/plan-list",
    pageName: "plan",
    img: <FaFileInvoiceDollar />,
    roles: ["admin", "subadmin"],
  },
];

export const SIDEBAR_BOTTOM_LINKS = [
  {
    id: 1,
    pathname: "/config",
    pageName: "Settings",
    img: <HiOutlineCog />,
    roles: ["admin", "subadmin"],
  },
  // {
  // 	key: 'support',
  // 	label: 'Help & Support',
  // 	path: '/support',
  // 	icon: <HiOutlineQuestionMarkCircle />
  // }
];
