import { createContext, useContext } from "react";

const DataContext = createContext();

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const values = {};

  return <DataContext.Provider value={values}>{children}</DataContext.Provider>;
};
