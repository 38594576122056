import { lazy } from "react";

const RoutesPaths = [
  {
    path: "/login",
    component: lazy(() => import("../../Components/SignIn/Login")),
    meta: {
      authRoute: false,
      roles: ["all"],
    },
  },
  {
    path: "/",
    component: lazy(() => import("../../Components/index")),
    meta: {
      authRoute: false,
      roles: ["all"],
    },
  },
  {
    path: "/privacy-policy",
    component: lazy(() => import("../../Components/privacy")),
    meta: {
      authRoute: false,
      roles: ["all"],
    },
  },

  
  {
    path: "/dashboard",
    component: lazy(() => import("../../Components/Dashboard/Dashboard")),
    meta: {
      authRoute: true,
      roles: ["subadmin", "admin"],
    },
  },

  {
    path: "/myaccount",
    component: lazy(() => import("../../Components/Profile/Profile")),
    meta: {
      authRoute: true,
      roles: ["subadmin", "admin"],
    },
    children: [
      {
        path: "changePassword",
        component: lazy(() =>
          import("../../Components/Profile/ChangePassword/ChangePassword")
        ),
        meta: {
          authRoute: true,
          roles: ["subadmin", "admin"],
        },
      },
    ],
  },
  {
    path: "/short-category",
    component: lazy(() => import("../../Components/ShortCategory/ShortCategory")),
    meta: {
      authRoute: true,
      roles: ["admin"],
    },
  },
  {
    path: "/novel-category",
    component: lazy(() => import("../../Components/NovelCategory/NovelCategory")),
    meta: {
      authRoute: true,
      roles: ["admin"],
    },
  },
  {
    path: "/labels",
    component: lazy(() => import("../../Components/Labels/Labels")),
    meta: {
      authRoute: true,
      roles: ["admin"],
    },
  },
  {
    path: "/language",
    component: lazy(() => import("../../Components/Language/Language")),
    meta: {
      authRoute: true,
      roles: ["admin"],
    },
  },
  {
    path: "/short-series",
    component: lazy(() => import("../../Components/ShortSeries/ShortSeries")),
    meta: {
      authRoute: true,
      roles: ["admin"],
    },
  },
  {
    path: "/Create-Episodes/:id",
    component: lazy(() => import("../../Components/ShortSeries/UploadVideo/UploadVideo")),
    meta: {
      authRoute: true,
      roles: ["admin"],
    },
  },
  {
    path: "/short-series/episodes/:id",
    component: lazy(() =>
      import("../../Components/ShortSeries/UploadVideo/EpisodeList")
    ),
    meta: {
      authRoute: true,
      roles: ["admin"],
    },
  },
  {
    path: "/short-series/upload-video/:id",
    component: lazy(() =>
      import("../../Components/ShortSeries/UploadVideo/UploadVideo")
    ),
    meta: {
      authRoute: true,
      roles: ["admin"],
    },
  },
  {
    path: "/countries",
    component: lazy(() => import("../../Components/Country/Country")),
    meta: {
      authRoute: true,
      roles: ["admin"],
    },
  },
  {
    path: "/user-list",
    component: lazy(() => import("../../Components/Users/Users")),
    meta: {
      authRoute: true,
      roles: ["admin"],
    },
  },
  {
    path: "/novel-list",
    component: lazy(() => import("../../Components/Novels/Novels")),
    meta: {
      authRoute: true,
      roles: ["admin"],
    },
  },
  {
    path: "/plan-list",
    component: lazy(() => import("../../Components/Plans/Plans")),
    meta: {
      authRoute: true,
      roles: ["admin"],
    },
  },
  {
    path: "/update-plan/:id",
    component: lazy(() => import("../../Components/Plans/AddPlans/CreatePlans")),
    meta: {
      authRoute: true,
      roles: ["admin"],
    },
  },
  {
    path: "/create-plan",
    component: lazy(() => import("../../Components/Plans/AddPlans/CreatePlans")),
    meta: {authRoute: true,roles: ["admin"]},
  },

  

  {
    path: "/config",
    component: lazy(() => import("../../Components/Setting/Setting")),
    meta: {
      authRoute: true,
      roles: ["admin"],
    },
  },
];



export default RoutesPaths;
