//login
export const SIGN_IN = "SIGN_IN";

//LABELS
export const GET_ALL_LABELS = "GET_ALL_LABELS";
export const SET_LABELS = "SET_LABELS";
export const UPDATE_LABELS = "UPDATE_LABELS";
export const DELETE_LABELS = "DELETE_LABELS";
export const DELETE_MULTIPLE_LABELS = "DELETE_MULTIPLE_LABELS";

//LANGUAGE
export const GET_ALL_LANGUAGE = "GET_ALL_LANGUAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const DELETE_LANGUAGE = "DELETE_LANGUAGE";
export const DELETE_MULTIPLE_LANGUAGE = "DELETE_MULTIPLE_LANGUAGE";

//COUNTRY
export const GET_ALL_COUNTRY = "GET_ALL_COUNTRY";
export const SET_COUNTRY = "SET_COUNTRY";
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const DELETE_COUNTRY = "DELETE_COUNTRY";
export const DELETE_MULTIPLE_COUNTRY = "DELETE_MULTIPLE_COUNTRY";


//MAIN_CATEGORY  
export const GET_ALL_MAIN_CATEGORY = "GET_ALL_MAIN_CATEGORY";
export const SET_MAIN_CATEGORY = "SET_MAIN_CATEGORY";
export const UPDATE_MAIN_CATEGORY = "UPDATE_MAIN_CATEGORY";
export const DELETE_MAIN_CATEGORY = "DELETE_MAIN_CATEGORY";
export const DELETE_MULTIPLE_MAIN_CATEGORY = "DELETE_MULTIPLE_MAIN_CATEGORY";


//SHORT_CATEGORY
export const GET_ALL_SHORT_CATEGORY = "GET_ALL_SHORT_CATEGORY";
export const SET_SHORT_CATEGORY = "SET_SHORT_CATEGORY";
export const UPDATE_SHORT_CATEGORY = "UPDATE_SHORT_CATEGORY";
export const DELETE_SHORT_CATEGORY = "DELETE_SHORT_CATEGORY";
export const DELETE_MULTIPLE_SHORT_CATEGORY = "DELETE_MULTIPLE_MAIN_CATEGORY";


//SHORT_SERIES
export const GET_ALL_SHORT_SERIES = "GET_ALL_SHORT_SERIES";
export const SET_SHORT_SERIES = "SET_SHORT_SERIES";
export const UPDATE_SHORT_SERIES = "UPDATE_SHORT_SERIES";
export const DELETE_SHORT_SERIES = "DELETE_SHORT_SERIES";
export const DELETE_MULTIPLE_SHORT_SERIES = "DELETE_MULTIPLE_SHORT_SERIES";

//SHORT_USER
export const GET_ALL_USER = "GET_ALL_USER";
export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const DELETE_MULTIPLE_USER = "DELETE_MULTIPLE_USER";

//EPISODE
export const GET_ALL_EPISODE = "GET_ALL_EPISODE";
export const SET_EPISODE = "SET_EPISODE";
export const UPDATE_EPISODE = "UPDATE_EPISODE";
export const DELETE_EPISODE = "DELETE_EPISODE";
export const DELETE_MULTIPLE_EPISODE = "DELETE_MULTIPLE_EPISODE";



//NOVEL_CATEGORY
export const GET_ALL_NOVEL_CATEGORY = "GET_ALL_NOVEL_CATEGORY";
export const SET_NOVEL_CATEGORY = "SET_NOVEL_CATEGORY";
export const UPDATE_NOVEL_CATEGORY = "UPDATE_NOVEL_CATEGORY";
export const DELETE_NOVEL_CATEGORY = "DELETE_NOVEL_CATEGORY";
export const DELETE_MULTIPLE_NOVEL_CATEGORY = "DELETE_MULTIPLE_MAIN_CATEGORY";


//NOVELS
export const GET_ALL_NOVEL = "GET_ALL_NOVEL";
export const SET_NOVEL = "SET_NOVEL";
export const UPDATE_NOVEL = "UPDATE_NOVEL";
export const DELETE_NOVEL = "DELETE_NOVEL";
export const DELETE_MULTIPLE_NOVEL = "DELETE_MULTIPLE_NOVEL";



// DASHOAED_DATA
export const GET_ALL_DASHOAED_DATA = "GET_ALL_DASHOAED_DATA";


// PLANS
export const GET_ALL_PLAN = "GET_ALL_PLAN";
export const SET_PLAN = "SET_PLAN";
export const UPDATE_PLANS = "UPDATE_PLAN";
export const DELETE_PLAN = "DELETE_PLAN";
export const DELETE_MULTIPLE_PLAN = "DELETE_MULTIPLE_PLAN";



// CONFIG
export const GET_ALL_CONFIG = "GET_ALL_CONFIG";
export const UPDATE_CONFIG = "UPDATE_CONFIG";