import React from "react";
import styles from "./style/style";
function Loader() {
  return (
    <div className={`${styles.model} flex justify-center items-center`}>
      <span className="spinner flex justify-center items-center h-screen"></span>
    </div>
  );
}

export default Loader;
