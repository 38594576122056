import * as types from "./ActionType";

const initialState = {
  Token: null,
  MainCategory: [],
  ShortCategory: [],
  ShortSeries:[],
  Country:[],
  Labels:[],
  dashboardData:{},
  Users:[],
  Episodes:[],
  Language:[],
  NovelCategory:[],
  Novels:[],
  Plans:[],
  Configs: {}  // Configs should be an object, not an array
  
};

const userReducers = (state = initialState, action) => {
  const { type, payload } = action;

  // const updateState = (key) => ({
  //   ...state,
  //   [key]: state[key].map((item) =>
  //     item._id === payload[`${key}_id`]
  //       ? { ...item, ...payload[`Updated${key}`] }
  //       : item
  //   ),
  // });

  const updateState = (key) => {
    console.log('key--',key)
    console.log(payload[`Updated${key}`]);
    console.log(`${key}Id`);
    const updatedState = {
      ...state,
      [key]: state[key].map((item) =>
        item._id === payload.Id // Make sure this matches your actual ID field
          ? { ...item, ...payload[`Updated${key}`] }
          : item
      ),
    };

    console.log('Updated State:', updatedState[key]);
    return updatedState;
  };

  // const deleteFromState = (key) => ({
  //   ...state,
  //   [key]: state[key].filter((item) => item._id !== payload[`${key}Id`]),
  // });

  const deleteFromState = (key) => ({
      ...state,
      [key]: state[key].filter((item) => item._id !== payload.Id),
    });

  const deleteMultipleFromState = (key) => ({
    ...state,
    [key]: state[key].filter(
      (item) => !payload[`${key}Ids`].includes(item._id)
    ),
  });

  switch (type) {
    // Add cases for Login actions
    case types.SIGN_IN:
      return { ...state, Token: payload };

    // Add cases for MainCategory actions
    case types.GET_ALL_MAIN_CATEGORY:
      return { ...state, MainCategory: payload, loading: false };
    case types.SET_MAIN_CATEGORY:
      return { ...state, MainCategory: [payload,...state.MainCategory] };
    case types.UPDATE_MAIN_CATEGORY:
      return updateState("MainCategory");
    case types.DELETE_MAIN_CATEGORY:
      return deleteFromState("MainCategory");
    case types.DELETE_MULTIPLE_MAIN_CATEGORY:
      return deleteMultipleFromState("MainCategory"); 
      
      
    
    // Add cases for ShortCategory actions
    case types.GET_ALL_SHORT_CATEGORY:
      return { ...state, ShortCategory: payload, loading: false };
    case types.SET_SHORT_CATEGORY:
      return { ...state, ShortCategory: [payload,...state.ShortCategory] };
    case types.UPDATE_SHORT_CATEGORY:
      return updateState("ShortCategory");
    case types.DELETE_SHORT_CATEGORY:
      return deleteFromState("ShortCategory");
    case types.DELETE_MULTIPLE_SHORT_CATEGORY:
      return deleteMultipleFromState("ShortCategory"); 


    // Add cases for Short actions
    case types.GET_ALL_SHORT_SERIES:
      return { ...state, ShortSeries: payload, loading: false };
    case types.SET_SHORT_SERIES:
      return { ...state, ShortSeries: [payload,...state.ShortSeries] };
    case types.UPDATE_SHORT_SERIES:
      return updateState("ShortSeries");
    case types.DELETE_SHORT_SERIES:
      return deleteFromState("ShortSeries");
    case types.DELETE_MULTIPLE_SHORT_SERIES:
      return deleteMultipleFromState("ShortSeries");

      // Add cases for country actions
    case types.GET_ALL_COUNTRY:
      return { ...state, Country: payload, loading: false };
    case types.SET_COUNTRY:
      return { ...state, Country: [payload,...state.Country] };
    case types.UPDATE_COUNTRY:
      return updateState("Country");
    case types.DELETE_COUNTRY:
      return deleteFromState("Country");
    case types.DELETE_MULTIPLE_COUNTRY:
      return deleteMultipleFromState("Country");

      // Add cases for labels actions
      case types.GET_ALL_LABELS:
        return { ...state, Labels: payload, loading: false };
      case types.SET_LABELS:
        return { ...state, Labels: [payload,...state.Labels] };
      case types.UPDATE_LABELS:
        return updateState("Labels");
      case types.DELETE_LABELS:
        return deleteFromState("Labels");
      case types.DELETE_MULTIPLE_LABELS:
        return deleteMultipleFromState("Labels");  


      // Add cases for User actions
      case types.GET_ALL_USER:
        return { ...state, Users: payload, loading: false };
      case types.SET_USER:
        return { ...state, Users: [payload,...state.Users] };
      case types.UPDATE_USER:
        return updateState("Users");
      case types.DELETE_USER:
        return deleteFromState("Users");
      case types.DELETE_MULTIPLE_USER:
        return deleteMultipleFromState("Users");
        
      
      // Add cases for Episode actions
      case types.GET_ALL_EPISODE:
        return { ...state, Episodes: payload, loading: false };
      case types.SET_EPISODE:
        return { ...state, Episodes: [payload,...state.Episodes] };
      case types.UPDATE_EPISODE:
        return updateState("Episodes");
      case types.DELETE_EPISODE:
        return deleteFromState("Episodes");
      case types.DELETE_MULTIPLE_EPISODE:
        return deleteMultipleFromState("Episodes");  

      case types.GET_ALL_DASHOAED_DATA:
        return {...state,dashboardData: payload};  

    // Add cases for Language actions
    case types.GET_ALL_LANGUAGE:
      return { ...state, Language: payload, loading: false };
    case types.SET_LANGUAGE:
      return { ...state, Language: [payload,...state.Language] };
    case types.UPDATE_LANGUAGE:
      return updateState("Language");
    case types.DELETE_LANGUAGE:
      return deleteFromState("Language");
    case types.DELETE_MULTIPLE_LANGUAGE:
      return deleteMultipleFromState("Language"); 

    // Add cases for NovelCategory actions
    case types.GET_ALL_NOVEL_CATEGORY:
      return { ...state, NovelCategory: payload, loading: false };
    case types.SET_NOVEL_CATEGORY:
      return { ...state, NovelCategory: [payload,...state.NovelCategory] };
    case types.UPDATE_NOVEL_CATEGORY:
      return updateState("NovelCategory");
    case types.DELETE_NOVEL_CATEGORY:
      return deleteFromState("NovelCategory");
    

     // Add cases for Novels actions
    case types.GET_ALL_NOVEL:
      return { ...state, Novels: payload, loading: false };
    case types.SET_NOVEL:
      return { ...state, Novels: [payload,...state.Novels] };
    case types.UPDATE_NOVEL:
      return updateState("Novels");
    case types.DELETE_NOVEL:
      return deleteFromState("Novels");
    case types.DELETE_MULTIPLE_NOVEL:
      return deleteMultipleFromState("Novels");  

    // Add cases for Plans actions
    case types.GET_ALL_PLAN:
      return { ...state, Plans: payload, loading: false };
    case types.SET_PLAN:
      return { ...state, Plans: [payload,...state.Plans] };
    case types.UPDATE_PLANS:
      return updateState("Plans");
    case types.DELETE_PLAN:
      return deleteFromState("Plans");
    case types.DELETE_MULTIPLE_PLAN:
      return deleteMultipleFromState("Plans");  
      
      
    // Configs
    case types.GET_ALL_CONFIG:
      return { ...state, Configs: payload, loading: false };
    case types.UPDATE_CONFIG:
      return { ...state, Configs: { ...state.Configs, ...payload } };

    default:
      return state;
  }
};

export default userReducers;
