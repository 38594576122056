import React, { Suspense } from "react";
import Router from "./Admin/Routers/router";
import SideBar from "./Admin/Components/LayOut/SideBar";
import { useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Header from "./Admin/Components/LayOut/Header";
import Loader from "./Admin/Components/Loader";

const App = () => {
  const location = useLocation();
  const { pathname } = location;
  const shouldRenderNavbar = ["/login"].includes(pathname) || ["/"].includes(pathname) || ["/privacy-policy"].includes(pathname) ||  pathname.startsWith("/Create-Episodes");
  
  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 2500,
        }}
        containerStyle={{
          zIndex: 99999999, // For the container
        }}
      />
      <div className="flex flex-row bg-neutral-100 h-screen w-screen font-PoppinsRegular overflow-hidden">
        {!shouldRenderNavbar && (
            <SideBar />
        )}

        <div className="w-full h-[100vh]">
          {!shouldRenderNavbar && (
            <Header/>
          )}

          {/* <div className="px-5 h-[calc(100vh-10.5%)] overflow-y-scroll">
            <Suspense fallback={<Loader />}>
              <Router />
            </Suspense>
          </div> */}

          {!shouldRenderNavbar ? (
            <div className="px-5 h-[calc(100vh-10.5%)] overflow-y-scroll">
              <Suspense fallback={<Loader />}>
                <Router />
              </Suspense>
            </div>
          ) : (
            <div className="h-[calc(100vh-10.5%)] overflow-y-scroll">
             <Suspense fallback={<Loader />}>
                <Router />
              </Suspense>
            </div>
          )}

         


        </div>
      </div>
    </div>
  );
};

export default App;
