import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import RoutesPaths from "./routes/index";
import Error from "../Components/404/PageNotFound";
import { useEffect } from "react";
import { getToken, isUserLoggedOut } from "../Components/Utils/util";

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to top when the path changes
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return children;
};

const Router = () => {
  const location = useLocation();
  const userToken = getToken();
  const FinalRoute = (props) => {
    const route = props?.route;
    if (route?.meta?.authRoute) {
      const allowedRoles = route.meta.roles;
      if (allowedRoles.includes(userToken?.role)) {
        return <route.component {...props} />;
      } else {
        // Redirect to login if the user doesn't have any of the allowed roles
        isUserLoggedOut();
        return (<Navigate to="/login" replace={true} state={{ path: location?.pathname }}/>);
      }
    } else {
      return <route.component {...props} />;
    }
  };

  return (
    <ScrollToTop>
      <Routes>
        {RoutesPaths.map((route, index) => {
          if (route.children) {
            return (
              <Route
                key={index}
                path={route?.path}
                element={<FinalRoute route={route} />}
              >
                {route.children.map((childRoute, childIndex) => (
                  <Route
                    key={childIndex}
                    path={childRoute.path}
                    element={<FinalRoute route={childRoute} />}
                  />
                ))}
              </Route>
            );
          } else {
            return (
              <Route
                exact
                key={index}
                path={route?.path}
                element={<FinalRoute route={route} />}
              />
            );
          }
        })}
        <Route exact path="*" element={<Error />} />
      </Routes>
    </ScrollToTop>
  );
};

export default Router;
