import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { headerData,SIDEBAR_BOTTOM_LINKS } from "../lib/constants/index";
import { getToken } from "../Utils/util";
import { CiMenuFries } from "react-icons/ci";
import Logo from "../img/Logo.png";



const linkClass =
	'flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base'
function SideBar() {
  const location = useLocation();
  const { pathname } = location;
  const [OpenBar, setOpenBar] = useState(false);

  const userData = getToken();
  return (
    <div className="bg-neutral-900 w-60 flex flex-col justify-between text-white">          
      <div className="z-50 ">
          <div className="flex items-center gap-2 px-1 py-3">
            <img src={Logo} alt="" srcSet="" className="mx-auto" />
          </div>
          <div className="h-[calc(100vh-97px-107px)] overflow-y-auto">
            <ul className="py-8 flex flex-1 flex-col gap-0.5">
              {userData &&
                headerData
                  ?.filter((data) => data?.roles?.includes(userData.role))
                  .map((data, index) => (
                    <Link
                      to={data.pathname}
                      className={` ${
                        data.pathname === pathname
                          ? "bg-neutral-700 text-white"
                          : " text-neutral-400"
                      } flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base`}
                      key={index}
                    >
                      <li
                        to={data.pathname}
                        className="flex items-center gap-3 pl-7"
                      >
                        <div className="text-lg">{data.img}</div>
                        <span className="text-md">{data.pageName}</span>
                      </li>
                    </Link>
                  ))}
            </ul>
          </div>
      
      </div>
      <div className="z-50">
     
          <div>
            <ul className="py-8 flex flex-1 flex-col gap-0.5">
              {userData &&
                SIDEBAR_BOTTOM_LINKS
                  ?.filter((data) => data?.roles?.includes(userData.role))
                  .map((data, index) => (
                    <Link
                      to={data.pathname}
                      className={` ${
                        data.pathname === pathname
                          ? "bg-neutral-700 text-white"
                          : " text-neutral-400"
                      } flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base`}
                      key={index}
                    >
                      <li
                        to={data.pathname}
                        className="flex items-center gap-3 pl-7"
                      >
                        <div className="text-lg">{data.img}</div>
                        <span className="text-xl">{data.pageName}</span>
                      </li>
                    </Link>
                  ))}
            </ul>
          </div>
      
      </div>
     
    </div>
  );
}

export default SideBar;

